import React, { useEffect, useRef, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { useAtom } from "jotai";
import {
  fetchMoreChattingListAtom,
  initializeAtom,
  whenChatbotChangeAtom,
} from "../../ViewModels/ChatViewModel";
import { ChatInput } from "../../Components/Chat/ChatInput";
import { RecommendQuestionButton } from "../../Components/Chat/RecommendQuestionButton";
import { RecommendQuestion } from "../../Components/Chat/RecommendQuestion";
import {
  chattingListAtom,
  currentChatbotAtom,
  scrollStateAtom,
} from "../../Atoms/ChatAtom";
import { NoChatbot } from "../../Components/Chat/NoChatbot";
import { Balloon } from "../../Components/Chat/Balloon";

export function Chat() {
  const [currentChatbot] = useAtom(currentChatbotAtom);
  const [chattingList] = useAtom(chattingListAtom);

  const [, initialize] = useAtom(initializeAtom);
  const [, whenChatbotChange] = useAtom(whenChatbotChangeAtom);
  const [isPageGettingHistory, fetchMoreChattingList] = useAtom(
    fetchMoreChattingListAtom
  );

  const [scrollState, setScrollState] = useAtom(scrollStateAtom);

  const chatEndRef = useRef<HTMLDivElement | null>(null);
  const chatContainerRef = useRef<HTMLDivElement | null>(null);

  const handleScroll = () => {
    if (chatContainerRef.current) {
      const container = chatContainerRef.current;

      // 스크롤이 맨 위에 도달했을 때
      if (container.scrollTop === 0) {
        const previousScrollHeight = container.scrollHeight; // 대화 가져오기 전의 전체 높이

        // 대화를 더 가져오고
        fetchMoreChattingList().then(() => {
          setTimeout(() => {
            const newScrollHeight = container.scrollHeight; // 새로운 전체 높이
            const scrollDifference = newScrollHeight - previousScrollHeight;

            // 대화가 추가된 만큼 스크롤 위치를 조정하여 이전 위치 유지
            container.scrollTop = scrollDifference;
          }, 0);
        });
      }
    }
  };

  useEffect(() => {
    return () => initialize();
  }, []);

  useEffect(() => {
    whenChatbotChange(chatEndRef.current);
  }, [currentChatbot]);

  useEffect(() => {
    if (chatEndRef.current && chatContainerRef.current) {
      const container = chatContainerRef.current;
      const distanceFromBottom =
        container.scrollHeight - container.scrollTop - container.clientHeight;

      // 밑에서 50px 이내일 경우 자동 스크롤
      if (distanceFromBottom <= 100) {
        chatEndRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [chattingList]);

  useEffect(() => {
    if (scrollState && chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: "smooth" });
      setScrollState(false);
    }
  }, [scrollState]);

  if (currentChatbot === null) return <NoChatbot />;

  return (
    <Stack
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          zIndex: 10,
          top: 0,
          width: "100%",
          left: 0,
          height: isPageGettingHistory ? "auto" : "0",
          background: isPageGettingHistory
            ? "rgba(0,32,96,0.8)"
            : "transparent",
          transition: "box-shadow 0.3s ease",
          textAlign: "center",
          color: "white",
          borderRadius: isPageGettingHistory ? "0 0 15px 15px" : null,
        }}
      >
        {isPageGettingHistory ? "대화 기록 가져오는 중" : ""}
      </Box>
      <Stack
        sx={{
          flexGrow: 1,
          overflowY: "auto",
          padding: 2,
        }}
        ref={chatContainerRef}
        onScroll={handleScroll}
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Stack
            sx={{
              width: "60%",
              gap: `20px`,
              "@media (max-width: 1024px)": {
                width: "80%",
              },
              "@media (max-width: 768px)": {
                width: "calc(100% - 40px)",
              },
            }}
          >
            {chattingList.map((chat, index) => (
              <Balloon
                key={`chatting-${index}`}
                name={chat.name}
                image={chat.image}
                text={chat.text}
                date={chat.date}
                chattingUser={chat.chattingUser}
              />
            ))}
            <div ref={chatEndRef} />
          </Stack>
        </Box>
      </Stack>
      <Stack
        sx={{
          width: "100%",
          background: "rgba(255, 255, 255,0)",
          position: "sticky",
          bottom: "0",
          paddingBottom: `20px`,
          justifyContent: "center",
          alignItems: "center",
          backdropFilter: `blur(10px)`,
          WebkitBackdropFilter: `blur(10px)`,
        }}
      >
        <Stack
          sx={{
            width: "60%",
            height: "100%",
            marginBottom: `10px`,
            alignItems: "center",
            justifyContent: "center",
            gap: `5px`,
            "@media (max-width: 1024px)": {
              width: "80%",
            },
            "@media (max-width: 768px)": {
              width: "calc(100% - 40px)",
            },
          }}
          direction="column"
        >
          <ChatInput />
          <Typography sx={{ color: "#8f8f8f", fontSize: "13px" }}>
            AI를 활용한 답변으로, 활용에 주의가 필요합니다.
          </Typography>
          <RecommendQuestion />
          <RecommendQuestionButton />
        </Stack>
      </Stack>
    </Stack>
  );
}
