import { atom } from "jotai";
import {
  chatHistoryPageNumAtom,
  chatInputValueAtom,
  chattingListAtom,
  chattingTypeAtom,
  ChatType,
  currentChatbotAtom,
  currentSearchIndexAtom,
  isAIMakingResponseAtom,
  isChattingAskAtom,
  isOpenRecommendQuestionAtom,
  isPageGettingHistoryAtom,
  scrollStateAtom,
  searchInputValueAtom,
} from "../Atoms/ChatAtom";
import { getChatbotHistory, requestChatbot } from "../Queries/ChatbotQueries";
import { userIdAtom } from "../Atoms/RootAtom";
import dayjs from "dayjs";

export const initializeAtom = atom(null, (get, set) => {
  set(currentChatbotAtom, null);
});

export const handleClickAtom = atom(
  null,
  (_, set, event: React.MouseEvent<HTMLDivElement>) => {
    set(isChattingAskAtom, event.currentTarget);
  }
);

export const handleCloseAtom = atom(null, (_, set) => {
  set(isChattingAskAtom, null);
});

export const handleCloseAndChangeTypeAtom = atom(
  null,
  (get, set, type: ChatType) => {
    const chatType = get(chattingTypeAtom);
    if (chatType !== type) {
      set(isChattingAskAtom, null);
      set(chattingTypeAtom, type);
      set(currentSearchIndexAtom, 0);
    }
  }
);

export const handleInputValueAtom = atom(null, (get, set, value: string) => {
  const chatType = get(chattingTypeAtom);

  if (chatType === ChatType.ASK) set(chatInputValueAtom, value);
  else {
    set(currentSearchIndexAtom, 0);
    set(searchInputValueAtom, value);
  }
});

export const hanldeRecommendQuestionAtom = atom(
  null,
  (get, set, value: string) => {
    set(chattingTypeAtom, ChatType.ASK);
    set(chatInputValueAtom, value);
    set(isOpenRecommendQuestionAtom, false);
  }
);

export const whenChatbotChangeAtom = atom(
  null,
  async (get, set, ref: HTMLDivElement | null) => {
    set(chattingListAtom, []);
    set(currentSearchIndexAtom, 0);
    set(isAIMakingResponseAtom, false);

    const currentChatbot = get(currentChatbotAtom);
    const userId = get(userIdAtom);

    if (currentChatbot && userId) {
      set(chatHistoryPageNumAtom, 1);
      const result = await getChatbotHistory(currentChatbot.id, userId, 1);

      if (result?.length !== 0 && result) {
        set(chattingListAtom, result);

        if (ref) {
          setTimeout(() => ref.scrollIntoView({ behavior: "smooth" }), 500);
        }
      } else if (result?.length === 0 && result) {
        const handleStreamMessage = (message: any, isFirst: boolean) => {
          if (isFirst) {
            set(chattingListAtom, (current) => [
              ...current,
              {
                name: currentChatbot.name,
                image: `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${
                  currentChatbot!.character_image!.file_path
                }`,
                text: message,
                date: dayjs().format("MM.DD HH:mm"),
                chattingUser: "chatbot",
                type: "markdown",
              },
            ]);
          } else {
            set(chattingListAtom, (current) => {
              const lastItem = current[current.length - 1];

              if (lastItem) {
                return [
                  ...current.slice(0, -1),
                  {
                    ...lastItem,
                    text: message,
                  },
                ];
              }

              return current;
            });
          }
        };

        await requestChatbot(
          process.env.REACT_APP_ACCESS_KEY,
          "",
          currentChatbot.chatbot_socket_parameter,
          -1,
          userId ?? "",
          handleStreamMessage,
          () => {}
        );
      }
    }
  }
);

export const fetchMoreChattingListAtom = atom(
  (get) => get(isPageGettingHistoryAtom),
  async (get, set) => {
    const isPageGettingHistory = get(isPageGettingHistoryAtom);

    if (isPageGettingHistory) return;

    set(isPageGettingHistoryAtom, true);

    const currentChatbot = get(currentChatbotAtom);
    const userId = get(userIdAtom);
    if (currentChatbot && userId) {
      const chatHistoryPageNum = get(chatHistoryPageNumAtom);
      const result = await getChatbotHistory(
        currentChatbot.id,
        userId,
        chatHistoryPageNum + 1
      );
      if (result) {
        if (result.length !== 0)
          set(chatHistoryPageNumAtom, (current) => current + 1);

        set(chattingListAtom, (prevChattingList) => [
          ...result,
          ...prevChattingList,
        ]);
      }
    }

    setTimeout(() => set(isPageGettingHistoryAtom, false), 500);
  }
);

export const handleInputFieldKeyDownAtom = atom(
  null,
  async (
    get,
    set,
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      const inputValue = get(chatInputValueAtom).trim();
      if (inputValue === "") return;

      const flow = get(currentChatbotAtom);
      set(isAIMakingResponseAtom, true);

      if (flow) {
        const newMessage = {
          name: flow.name,
          image: `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${
            flow!.character_image!.file_path
          }`,
          text: inputValue,
          date: dayjs().format("MM.DD HH:mm"),
          chattingUser: "user",
        };

        set(chattingListAtom, (prevChatting) => [...prevChatting, newMessage]);
        set(chatInputValueAtom, "");
        set(isOpenRecommendQuestionAtom, false);

        set(chattingListAtom, (current) => [
          ...current,
          {
            name: flow.name,
            image: `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${
              flow!.character_image!.file_path
            }`,
            text: "",
            date: dayjs().format("MM.DD HH:mm"),
            chattingUser: "chatbot",
          },
        ]);

        // 여기에서 backend랑 통신을 하도록 하자.
        const handleStreamMessage = (
          message: string,
          isFirst: boolean,
          flow_socket_name: string
        ) => {
          const currentChatbot = get(currentChatbotAtom);

          if (
            currentChatbot &&
            currentChatbot.chatbot_socket_parameter !== flow_socket_name
          )
            return;
          set(chattingListAtom, (current) => {
            const lastItem = current[current.length - 1];

            if (isFirst) set(scrollStateAtom, true);
            if (lastItem) {
              if (lastItem.chattingUser === "user") {
                return [
                  ...current,
                  {
                    chattingUser: "chatbot",
                    text: message,
                    date: dayjs().format("MM.DD HH:mm"),
                    image: `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${
                      currentChatbot!.character_image!.file_path
                    }`,
                    name: currentChatbot!.name,
                  },
                ];
              } else {
                return [
                  ...current.slice(0, -1),
                  {
                    ...lastItem,
                    text: message,
                  },
                ];
              }
            }

            return current;
          });
        };

        await requestChatbot(
          process.env.REACT_APP_ACCESS_KEY,
          inputValue,
          get(currentChatbotAtom)!.chatbot_socket_parameter,
          -1,
          get(userIdAtom) ?? "",
          handleStreamMessage,
          () => {}
        );
      }

      set(isAIMakingResponseAtom, false);
    }
  }
);

export const handleSendBtnAtom = atom(null, async (get, set) => {
  const inputValue = get(chatInputValueAtom).trim();
  if (inputValue === "") return;

  const flow = get(currentChatbotAtom);
  set(isAIMakingResponseAtom, true);

  if (flow) {
    const newMessage = {
      name: flow.name,
      image: `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${
        flow!.character_image!.file_path
      }`,
      text: inputValue,
      date: dayjs().format("MM.DD HH:mm"),
      chattingUser: "user",
    };

    set(chattingListAtom, (prevChatting) => [...prevChatting, newMessage]);
    set(chatInputValueAtom, "");
    set(isOpenRecommendQuestionAtom, false);

    set(chattingListAtom, (current) => [
      ...current,
      {
        name: flow.name,
        image: `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${
          flow!.character_image!.file_path
        }`,
        text: "",
        date: dayjs().format("MM.DD HH:mm"),
        chattingUser: "chatbot",
      },
    ]);

    // 여기에서 backend랑 통신을 하도록 하자.
    const handleStreamMessage = (
      message: string,
      isFirst: boolean,
      flow_socket_name: string
    ) => {
      const currentChatbot = get(currentChatbotAtom);

      if (
        currentChatbot &&
        currentChatbot.chatbot_socket_parameter !== flow_socket_name
      )
        return;
      set(chattingListAtom, (current) => {
        const lastItem = current[current.length - 1];

        if (isFirst) set(scrollStateAtom, true);
        if (lastItem) {
          if (lastItem.chattingUser === "user") {
            return [
              ...current,
              {
                chattingUser: "chatbot",
                text: message,
                date: dayjs().format("MM.DD HH:mm"),
                image: `${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${
                  currentChatbot!.character_image!.file_path
                }`,
                name: currentChatbot!.name,
              },
            ];
          } else {
            return [
              ...current.slice(0, -1),
              {
                ...lastItem,
                text: message,
              },
            ];
          }
        }

        return current;
      });
    };

    await requestChatbot(
      process.env.REACT_APP_ACCESS_KEY,
      inputValue,
      get(currentChatbotAtom)!.chatbot_socket_parameter,
      -1,
      get(userIdAtom) ?? "",
      handleStreamMessage,
      () => {}
    );
  }

  set(isAIMakingResponseAtom, false);
});

export const whenDownButtonClickAtom = atom(null, (get, set) => {
  const currentIdx = get(currentSearchIndexAtom);
  const highlightsLength = document.getElementsByClassName(
    "highlight-search-field"
  ).length;

  setTimeout(() => {
    if (currentIdx + 1 < highlightsLength) {
      // currentIdx가 highlight 길이를 넘지 않도록
      const firstHighlight = document.getElementsByClassName(
        "highlight-search-field"
      )[currentIdx + 1];
      if (firstHighlight) {
        firstHighlight.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
      set(currentSearchIndexAtom, (current) => current + 1);
    }
  }, 100);
});

export const whenUpButtonClickAtom = atom(null, (get, set) => {
  const currentIdx = get(currentSearchIndexAtom);

  setTimeout(() => {
    const firstHighlight = document.getElementsByClassName(
      "highlight-search-field"
    )[currentIdx - 1];
    if (firstHighlight) {
      firstHighlight.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }

    set(currentSearchIndexAtom, (current) =>
      current != 0 ? current - 1 : current
    );
  }, 100);
});
